<template>
  <div>
    <!-- Controllers -->
    <div class="d-flex justify-end py-4 pr-6">
      <v-btn color="primary" class="mr-3" dark depressed @click="update()" v-text="$t('common.save')" />
    </div>
    <!-- Body -->
    <v-container class="px-6 pt-0 pb-6 bigScreenForm">
      <v-row>
        <v-col md="8" sm="12">
          <v-row>
            <v-col cols="12">
              <h2 class="mb-4" v-text="$t('project.editProject.generalInformation')" />
            </v-col>
            <v-col cols="12" class="py-1 px-3">
              <v-text-field
                v-model="form.name"
                :error-messages="getFieldErrors('name')" :label="$t('common.name')" outlined dense @blur="$v.form.name.$touch()"
              />
            </v-col>
            <v-col cols="12" class="py-1 px-3">
              <v-select
                v-model="form.language"
                :error-messages="getFieldErrors('language')"
                :label="$t('project.editProject.language')"
                :items="locales"
                item-value="code"
                item-text="language"
                outlined
                dense
                hide-details="auto"
                class="my-2"
                @blur="$v.form.language.$touch()"
              />
            </v-col>
            <!--
            <v-col cols="12" class="py-1 px-3">
              <v-text-field :label="$t('common.link')" outlined dense readonly disabled value="'https://admin-owqlo-staging.web.app/'">
                <template #append>
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-icon color="primary" v-on="on" @click="copyToClipboard('https://admin-owqlo-staging.web.app/')">
                        content_copy
                      </v-icon>
                    </template>
                    {{ $t('club.editClub.copyLink') }}
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col> -->
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <v-text-field
                v-model="form.timezone"
                :label="$t('common.timeZone')" outlined dense class="my-2" disabled
              />
            </v-col>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <v-text-field
                v-model="form.city"
                :label="$t('common.city')" outlined dense class="my-2" :error-messages="getFieldErrors('city')" @blur="$v.form.city.$touch()"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="py-1 px-3">
              <html-field
                v-model="form.detailDescription"
                :placeholder="$t('project.editProject.detailDescription')" :error-messages="getFieldErrors('detailDescription')"
                @blur="$v.form.detailDescription.$touch()"
              />
            </v-col>
          </v-row>
          <v-row class="mt-8">
            <v-col cols="12" sm="6" class="py-1 px-3">
              <div class="mb-4 d-flex">
                <h3 class="mr-3" v-text="$t('common.logo')" />
                <global-tooltip
                  :text="$t('common.tooltipImageSizes', {
                    proportion: '1:1',
                    width: '200px',
                    height: '200px'
                  })"
                />
              </div>
              <image-field
                v-model="form.logo"
                :error-messages="getFieldErrors('logo')"
                :width="200" :height="200"
                suffix="_logo" @input="$v.form.logo.$touch()"
              />
            </v-col>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <div class="mb-4 d-flex">
                <h3 class="mr-3" v-text="$t('project.editProject.banner')" />
                <global-tooltip
                  :text="$t('common.tooltipImageSizes', {
                    proportion: '16:9',
                    width: '640px',
                    height: '360px'
                  })"
                />
              </div>
              <image-field
                v-model="form.banner"
                :error-messages="getFieldErrors('banner')"
                :width="640" :height="360"
                suffix="_banner" @input="$v.form.banner.$touch()"
              />
            </v-col>
          </v-row>
          <v-row class="mt-8">
            <v-col class="field">
              <div class="mb-4 d-flex">
                <h3 class="mr-3" v-text="`${$t('project.editProject.video')} ${$t('common.optional')}`" />
                <global-tooltip
                  :text="$t('project.editProject.tooltipVideoYoutube')"
                />
              </div>
              <video-field v-model="form.video" @blur="$v.form.video.$touch()" />
            </v-col>
          </v-row>
          <v-row class="mt-8">
            <v-col class="field" cols="12">
              <h3 class="mb-4" v-text="$t('project.editProject.numberSubscribersTitle')" />
              <v-row class="d-flex align-center mx-0 my-3">
                <v-switch
                  v-model="form.seeNumberSubscribers"
                  class="mt-0 mr-3 py-1" hide-details="auto"
                  :label="$t('project.editProject.features.seeNumberSubscribers')" @change="$v.form.seeNumberSubscribers.$touch()"
                />
                <global-tooltip
                  :text="$t('project.editProject.features.seeNumberSubscribersTooltip')"
                />
              </v-row>
            </v-col>
          </v-row>
          <v-row class="mt-8">
            <v-col cols="12" class="pb-0">
              <h3 class="mb-4" v-text="$t('project.editProject.sponsorization')" />
              <v-checkbox v-model="form.hasAds" :label="$t('project.editProject.isSponsorized')" hide-details="auto" @change="$v.form.hasAds.$touch()" />
              <v-text-field
                v-if="form.hasAds" v-model="form.ads.link"
                class="mt-6" :error-messages="getFieldErrors('ads.link')" :label="$t('project.editProject.sponsorLink')"
                outlined dense @blur="$v.form.ads.link.$touch()"
              />
            </v-col>
          </v-row>
          <v-row v-if="form.hasAds" class="field">
            <v-col cols="12" sm="6">
              <div class="mb-4 d-flex">
                <h3 class="mr-3" v-text="$t('project.editProject.sponsorFooter')" />
                <global-tooltip
                  :text="$t('common.tooltipImageSizes', {
                    proportion: '8:1',
                    width: '640px',
                    height: '80px'
                  })"
                />
              </div>
              <image-field
                v-model="form.ads.footer"
                :error-messages="getFieldErrors('ads.footer')"
                :width="640" :height="80"
                suffix="_adFooter" @input="$v.form.ads.footer.$touch()"
              />
            </v-col>
          </v-row>
          <v-row class="mt-8">
            <v-col cols="12" class="py-1 px-3">
              <h2 class="mb-4" v-text="$t('guild.dataGuild')" />
            </v-col>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <v-autocomplete
                v-model="form.timezone"
                :error-messages="getFieldErrors('timezone')" :label="$t('common.timeZone')" :items="timezoneItems"
                outlined dense class="mb-1" @blur="$v.form.timezone.$touch()"
              />
            </v-col>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <date-range-field
                v-model="form.foundationDate" :is-single-date="true" :label="`${$t('cluster.foundationDate')} ${$t('common.optional')}`"
                :timezone="form.timezone" @input="newValue => form.foundationDate = newValue"
                @blur="$v.form.foundationDate.$touch()" @resetDate="form.foundationDate = null"
              />
            </v-col>
            <v-col cols="12" class="py-1 px-3">
              <v-text-field
                v-model="form.ministryRegistrationNumber"
                :label="`${$t('cluster.ministryRegistrationNumber')} ${$t('common.optional')}`" outlined dense
                @blur="$v.form.ministryRegistrationNumber.$touch()"
              />
            </v-col>
            <v-col cols="12" class="py-1 px-3">
              <v-text-field
                v-model="form.legalEntityId"
                :label="`${$t('cluster.legalEntityId')} ${$t('common.optional')}`" outlined dense @blur="$v.form.legalEntityId.$touch()"
              />
            </v-col>
            <v-col cols="12" class="py-1 px-3">
              <v-text-field
                v-model="form.address.line1"
                :label="`${$t('cluster.address')} ${$t('common.optional')}`" outlined dense @blur="$v.form.address.line1.$touch()"
              />
            </v-col>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <v-select
                v-model="form.address.country" :items="countryItems"
                :label="`${$t('user.fields.country')} ${$t('common.optional')}`" outlined dense append-icon="mdi-chevron-down"
              />
            </v-col>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <v-text-field
                v-model="form.address.region"
                :label="`${$t('cluster.region')} ${$t('common.optional')}`" outlined dense @blur="$v.form.address.region.$touch()"
              />
            </v-col>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <v-text-field
                v-model="form.address.city"
                :label="`${$t('common.city')} ${$t('common.optional')}`" outlined dense @blur="$v.form.address.city.$touch()"
              />
            </v-col>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <v-text-field
                v-model="form.address.zip"
                :label="`${$t('common.postalCode')} ${$t('common.optional')}`" outlined dense @blur="$v.form.address.zip.$touch()"
              />
            </v-col>
            <v-col cols="12" class="py-1 px-3">
              <v-text-field
                v-model="form.website"
                :error-messages="getFieldErrors('website')" :label="`${$t('cluster.website')} ${$t('common.optional')}`"
                outlined dense @blur="$v.form.website.$touch()"
              />
            </v-col>
            <v-col cols="12" class="py-1 px-3">
              <v-text-field
                v-model="form.email"
                :error-messages="getFieldErrors('email')" :label="`${$t('user.fields.email')} ${$t('common.optional')}`"
                outlined dense @blur="$v.form.email.$touch()"
              />
            </v-col>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <v-text-field
                v-model="form.twitter"
                :label="`${$t('common.twitter')} ${$t('common.optional')}`" outlined dense @blur="$v.form.twitter.$touch()"
              />
            </v-col>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <v-text-field
                v-model="form.facebook"
                :label="`${$t('common.facebook')} ${$t('common.optional')}`" outlined dense @blur="$v.form.facebook.$touch()"
              />
            </v-col>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <v-text-field
                v-model.number="form.numberRegisteredGuilds" type="number" min="1" :label="`${$t('cluster.numberRegisteredGuilds')} ${$t('common.optional')}`"
                outlined dense @keypress="validateKeyNumeric($event)" @blur="$v.form.numberRegisteredGuilds.$touch()"
              />
            </v-col>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <v-text-field
                v-model.number="form.numberPlayersGuildApproximate"
                type="number" min="1" :label="`${$t('cluster.numberPlayersGuildApproximate')} ${$t('common.optional')}`"
                outlined dense @keypress="validateKeyNumeric($event)" @blur="$v.form.numberPlayersGuildApproximate.$touch()"
              />
            </v-col>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <v-switch
                v-model="form.hasAgreementClub"
                :label="$t('cluster.hasAgreementClub')" @change="$v.form.hasAgreementClub.$touch()"
              />
            </v-col>
          </v-row>
          <v-row class="mt-8">
            <v-col cols="12" class="py-1 px-3">
              <h2 class="mb-4" v-text="$t('cluster.dataPresident')" />
            </v-col>
            <v-col cols="12" class="py-1 px-3">
              <phone-field
                v-model="form.phonePresident" outlined dense
                @blur="$v.form.phonePresident.$touch()"
              />
            </v-col>
            <v-col cols="12" class="py-1 px-3">
              <v-text-field
                v-model="form.emailPresident"
                :error-messages="getFieldErrors('emailPresident')" :label="$t('user.fields.email')" outlined dense @blur="$v.form.emailPresident.$touch()"
              />
            </v-col>
          </v-row>
          <v-row class="mt-8">
            <v-col cols="12" class="py-1 px-3">
              <h2 class="mb-4" v-text="$t('cluster.dataSecretary')" />
            </v-col>
            <v-col cols="12" class="py-1 px-3">
              <phone-field
                v-model="form.phoneSecretary" outlined dense
                @blur="$v.form.phoneSecretary.$touch()"
              />
            </v-col>
            <v-col cols="12" class="py-1 px-3">
              <v-text-field
                v-model="form.emailSecretary"
                :error-messages="getFieldErrors('emailSecretary')" :label="$t('user.fields.email')" outlined dense @blur="$v.form.emailSecretary.$touch()"
              />
            </v-col>
          </v-row>
          <v-row class="mt-8">
            <v-col cols="12" class="py-1 px-3">
              <h2 class="mb-4" v-text="$t('cluster.dataChargeApp')" />
            </v-col>
            <v-col cols="12" class="py-1 px-3">
              <phone-field
                v-model="form.phoneChargeApp" outlined dense
                @blur="$v.form.phoneChargeApp.$touch()"
              />
            </v-col>
            <v-col cols="12" class="py-1 px-3">
              <v-text-field
                v-model="form.emailChargeApp"
                :error-messages="getFieldErrors('emailChargeApp')" :label="$t('user.fields.email')"
                outlined dense @blur="$v.form.emailChargeApp.$touch()"
              />
            </v-col>
          </v-row>
          <v-row class="mt-8">
            <v-col cols="12" class="py-1 px-3">
              <h2 class="mb-4" v-text="$t('common.observations')" />
            </v-col>
            <v-col cols="12" class="py-1 px-3">
              <v-text-field
                v-model="form.numberRegistration"
                :label="$t('guild.numberRegistration')" outlined dense @blur="$v.form.numberRegistration.$touch()"
              />
            </v-col>
            <v-col cols="12" class="py-1 px-3">
              <v-text-field
                v-model="form.federationBelongs"
                :label="$t('guild.federationBelongs')" outlined dense @blur="$v.form.federationBelongs.$touch()"
              />
            </v-col>
            <v-col cols="12" class="py-1 px-3">
              <v-text-field
                v-model="form.accountNumber"
                :label="$t('cluster.accountNumber')" outlined dense @blur="$v.form.accountNumber.$touch()"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <confirm-save-dialog
      v-if="nextRoute && open"
      @close-dialog="open = false"
      @navigate="navigate"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { required, requiredIf, url, email, numeric } from 'vuelidate/lib/validators'
import formUtils from '@/utils/mixins/formUtils'
import saveDialogUtils from '@/utils/mixins/saveDialog'
import i18nCountries from '@/utils/mixins/i18nCountries'
import timezonesEnum from '@/enums/timezonesEnum'
import localeUtils from '@/utils/mixins/localeUtils'

export default {
  name: 'EditGuild',
  components: {
    HtmlField: () => import('@/components/formFields/HtmlField'),
    ImageField: () => import('@/components/formFields/ImageField'),
    VideoField: () => import('@/components/formFields/VideoField'),
    GlobalTooltip: () => import('@/components/GlobalTooltip'),
    ConfirmSaveDialog: () => import('@/components/ConfirmSaveDialog'),
    DateRangeField: () => import('@/components/formFields/DateRangeField'),
    PhoneField: () => import('@/components/formFields/PhoneField'),
  },
  mixins: [formUtils, saveDialogUtils, i18nCountries, localeUtils],
  props: {
    organizationId: { type: String, required: true },
    projectId: { type: String, required: true },
  },
  data: () => ({ form: {}, timezoneItems: timezonesEnum.map(v => ({ text: v, value: v })) }),
  computed: {
    ...mapGetters({ guild: 'project/data' }),
    ...mapGetters('project', ['isGuild']),
  },
  validations() {
    return {
      form: {
        name: { required },
        language: { required },
        city: { required },
        detailDescription: { required },
        logo: { required },
        banner: { required },
        video: { },
        seeNumberSubscribers: { },
        hasAds: {},
        ads: { full: {}, footer: { required: requiredIf(() => this.form.hasAds) }, link: { url } },
        timezone: { required: requiredIf(() => this.form.foundationDate || this.form.lastElectionDate) },
        foundationDate: {},
        ministryRegistrationNumber: {},
        legalEntityId: {},
        address: { line1: {}, country: {}, region: {}, city: {}, zip: {} },
        website: { url },
        email: { email },
        twitter: {},
        facebook: {},
        numberRegisteredGuilds: { numeric },
        numberPlayersGuildApproximate: { numeric },
        hasAgreementClub: {},
        phonePresident: { code: { }, number: { } },
        emailPresident: { email },
        phoneSecretary: { code: { }, number: { } },
        emailSecretary: { email },
        phoneChargeApp: { code: { }, number: { } },
        emailChargeApp: { email },
        numberRegistration: {},
        federationBelongs: {},
        accountNumber: {},
      },
    }
  },
  created() {
    this.form = {
      name: this.guild.name,
      language: this.guild.language ?? null,
      city: this.guild.city ?? null,
      detailDescription: this.guild.detailDescription ?? null,
      logo: this.guild.logo ?? null,
      banner: this.guild.banner ?? null,
      video: this.guild.video ?? null,
      seeNumberSubscribers: !this.guild?.features?.removeSubscribersLabel ?? false,
      hasAds: this.guild.ads?.footer ?? this.guild.ads?.full,
      ads: this.guild.ads ?? { full: null, footer: null, link: null },
      timezone: this.guild.timezone ?? null,
      foundationDate: this.guild.foundationDate ?? null,
      lastElectionDate: this.guild.lastElectionDate ?? null,
      dateLastMeeting: this.guild.dateLastMeeting ?? null,
      ministryRegistrationNumber: this.guild.ministryRegistrationNumber ?? null,
      legalEntityId: this.guild.legalEntityId ?? null,
      address: this.guild.address ?? {
        address: {
          line1: this.guild.address?.line1 ?? null,
          zip: this.guild.address?.zip ?? null,
          city: this.guild.address?.city ?? null,
          region: this.guild.address?.region ?? null,
          country: this.guild.address?.country ?? null,
        },
      },
      website: this.guild.website ?? null,
      email: this.guild.email ?? null,
      twitter: this.guild.twitter ?? null,
      facebook: this.guild.facebook ?? null,
      numberRegisteredGuilds: this.guild.numberRegisteredGuilds ?? null,
      numberPlayersGuildApproximate: this.guild.numberPlayersGuildApproximate ?? null,
      hasAgreementClub: this.guild.hasAgreementClub ?? false,
      phonePresident: this.guild.phonePresident
      ?? { phonePresident: { country: null, code: null, number: null } },
      emailPresident: this.guild.emailPresident ?? null,
      phoneSecretary: this.guild.phoneSecretary
      ?? { phoneSecretary: { country: null, code: null, number: null } },
      emailSecretary: this.guild.emailSecretary ?? null,
      phoneChargeApp: this.guild.phoneChargeApp
      ?? { phoneChargeApp: { country: null, code: null, number: null } },
      emailChargeApp: this.guild.emailChargeApp ?? null,
      numberRegistration: this.guild.numberRegistration ?? null,
      federationBelongs: this.guild.federationBelongs ?? null,
      accountNumber: this.guild.accountNumber ?? null,
    }
  },
  methods: {
    parseForm() {
      return (({ name, hasAds, ads, ...rest }) => ({
        ...rest,
        name: name.trim(),
        ads: hasAds ? ads : null,
        features: {
          ...this.guild?.features,
          removeSubscribersLabel: !this.form.seeNumberSubscribers,
        },
      }))(this.form)
    },
    async update() {
      const { organizationId, projectId } = this
      if (!this.isFormValid()) {
        this.scrollToFirstError()
        return
      }
      await this.runAsync(() => this.$store.dispatch('guild/update', { organizationId, projectId, data: this.parseForm() }))
      this.userAgreedToLeave = true
    },
  },
}
</script>
